import network from "@/api/network";

export default class Delivery {
  static async getDelivery(page, perPage, data) {
    return await network.post(`cvp/orders/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async confirmDelivery(data) {
    return await network.post("cvp/orders/accept", data, {});
  }

  static async revokeDelivery(data) {
    return await network.post("cvp/orders/revoke", data, {});
  }

  static async changeDelivery(data) {
    return await network.put("cvp/orders", data, {});
  }

  static async addDelivery(data) {
    return await network.post("cvp/orders", data, {});
  }

  static async exportDelivery(data, config) {
    return await network.post("cvp/orders/filter/download/report", data, config);
  }

  static async getDeliveryTypes() {
    return await network.get("cvp/orders/list/types", null, {});
  }

  static async getDeliveryStatus() {
    return await network.get("cvp/orders/list/statuses", null, {});
  }

  static async getPhysicalPrizes(projectId) {
    return await network.get(`prize/list/promo/project/${projectId}`);
  }

  static async getBalance() {
    return await network.get("cvp/payment-services-balances", null, {});
  }
}
