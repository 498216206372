import network from "@/api/network";

export default class Promo {
  // PROMOS
  static async getPromoList(page, perPage, data) {
    return await network.get(`actions/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async getAvailableRoles(data) {
    return await network.get("actions/roles/list", data, {});
  }

  static async getArticleGroups(data) {
    return await network.get("actions/articles/groups", data, {});
  }

  static async addPromoFiles(data) {
    return await network.post("actions/file/store", data, {});
  }

  static async createPromo(data) {
    return await network.post("actions", data, {});
  }

  static async modifyPromo(data) {
    return await network.put("actions", data, {});
  }

  static async deletePromo(data) {
    return await network.delete("actions", data, {});
  }

  static async exportPromoList(data, config) {
    return await network.get("actions/export/list/filter", data, config);
  }

  // PARTICIPANTS
  static async getPromoParticipants(page, perPage, data) {
    return await network.get(`actions/participants/list/filter?page=${page}&per_page=${perPage}`, data, {});
  }

  static async exportPromoParticipants(data, config) {
    return await network.get("actions/participants/export/list/filter", data, config);
  }
}
