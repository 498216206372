<template>
  <v-app>
    <router-view />
    <div class="preloader-custom" v-if="preloader > 0">
      <Preloader />
    </div>
  </v-app>
</template>

<script>
import Preloader from "@/components/Preloader.vue";

export default {
  name: "App",
  components: {
    Preloader,
  },
  computed: {
    preloader() {
      return this.$store.getters.PRELOADER;
    },
  },
  async mounted() {
    if (window.location.href.indexOf("token") > -1) {
      let url = new URL(window.location.href);
      let token = url.searchParams.get("token");
      await this.$store.dispatch("user/redirectLogin", token);
    }
  },
};
</script>

<style lang="scss">
.preloader-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1039;
  background-color: #ffffff99;
  animation: preloader-custom 0.5s ease forwards;
}

@keyframes preloader-custom {
  from {
    backdrop-filter: blur(0x);
    opacity: 0;
  }
  to {
    backdrop-filter: blur(8px);
    opacity: 1;
  }
}
</style>
